import { FacebookPixel } from '@barstoolsports/web-sdk'

class OldRowFacebookPixel extends FacebookPixel {
  constructor() {
    super([process.env.NEXT_PUBLIC_FB_TRACKING_ID || ''])
  }

  async pageView() {
    this.sendEvent('track', 'PageView', {}, {})
  }

  async loadScript() {
    this.init()
  }
}

export default new OldRowFacebookPixel()
