import FacebookPixel from './facebook'

export const loadPixels = () => {
  FacebookPixel.loadScript()
}

export const pageView = () => {
  FacebookPixel.pageView()
}

const actions  = {
  loadPixels,
  pageView
}

export default actions
