import '../src/styles/globals.css'
import Head from 'next/head'
import type { AppProps } from 'next/app'
import { loadPixels } from '@lib/pixels'
import Script from 'next/script'

import { usePageViewAnalytics } from '@lib/analytics'
import { useEffect } from 'react'
import { useSyncSegment } from '@barstoolsports/web-sdk'

function MyApp({ Component, pageProps }: AppProps) {
  usePageViewAnalytics()
  useSyncSegment(process.env.NEXT_PUBLIC_SEGMENT_KEY || '')

  useEffect(() => {
    loadPixels()
  }, [])

  return (
    <>
      <Head>
        <link rel='icon' href='/favicon.png' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      </Head>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`}
      />
      <Script
        id='data-layer-script'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `
        }}
      />
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA4_TRACKING_ID}`}
      />
      <Script
        id='data-layer-script-ga4'
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GA4_TRACKING_ID}');
          `
        }}
      ></Script>
      <Script
        id='google-adsync-script'
        async
        src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.NEXT_PUBLIC_GA_SYNDICATION_ID}`}
        crossOrigin='anonymous'
      />
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
